@import "src/assets/scss/vars.module";

.root {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 320px;
  padding-top: 10px;
}

.title {
  font-family: Open Sans, sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 10px;
  width: 100%;
}

.text {
  width: 100%;
  text-align: center;
  font-size: 14px;
  line-height: 22px;
  color: $typography_grey;
  margin-bottom: 10px;
}

.iconWrapper {
  margin-top: auto;
  margin-bottom: auto;
}

.buttonWrapper {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 0;
}

.button {
  width: 100%;
}