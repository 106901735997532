.root {
  position: relative;
  width: 100%;
}

.holder {
  display: block;
  width: 100%;
}

.image {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}