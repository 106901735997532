@import "src/assets/scss/breakpoints";
@import "src/assets/scss/vars.module";

.item {
  text-align: left;
  padding: 10px 15px;
  cursor: pointer;
  border-bottom: 1px solid $primary_border;
  &:hover {
    background: #E2E4E7;
  }
}

.city {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #2F2E37;
  line-height: 22px;

  @include respond-to(m) {
    font-size: 16px;
  }
}

.region {
  font-size: 14px;
  color: #828282;
}
