@import "src/assets/scss/vars.module";
@import "src/assets/scss/breakpoints";

.wrapper {
  width: 100%;
  max-width: 1366px;
  margin: 0 auto;
  overflow-x: hidden;
  min-height: 100vh;
  background: $background;
}

.copyright {
  background: #4D3772;
  max-width: 1366px;
  margin: 0 auto;
  width: 100%;
}

.header {
  height: auto;
  padding: 0 50px;
}

.zodiacs {
  width: 91%;
  margin: 30px auto 0 auto;
  @include respond-to(l) {
    max-width: 51%;
  }
  @include respond-to(m) {
    display: flex;
  }
}
