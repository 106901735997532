@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');

@font-face {
  font-family: "montserrat-bold";
  src: url("../fonts/Montserrat-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "montserrat-light";
  src: url("../fonts/Montserrat-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "montserrat-medium";
  src: url("../fonts/Montserrat-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "montserrat-reg";
  src: url("../fonts/Montserrat-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "montserrat-semi";
  src: url("../fonts/Montserrat-SemiBold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
