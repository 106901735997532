@import "src/assets/scss/breakpoints";
@import "src/assets/scss/vars.module";

.text {
  font-size: 14px;
  margin-bottom: 10px;
  color: $secondary_text;
  line-height: 24px;
  @include respond-to(m) {
    font-size: 16px;
    margin-bottom: 30px
  }
  @include respond-to(l) {
    font-size: 18px;
  }
}

.autocomplete {
  position: relative;
}
