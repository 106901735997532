@import "src/assets/scss/breakpoints";
@import "src/assets/scss/mixins";
@import "src/assets/scss/vars.module";

$paddingBottom: 32px;
$paddingBottomTablet: 120px;
$paddingBottomDesktop: 90px;
$buttonHeight: 40px;
$buttonHeightTablet: 44px;

div.container {
  padding-top: 32px;
  padding-bottom: calc(#{$paddingBottom} + #{$buttonHeight});

  @include respond-to(m) {
    padding-top: 47px;
    padding-bottom: $paddingBottomTablet;
  }

  @include respond-to(l) {
    padding-top: 36px;
    padding-bottom: $paddingBottomDesktop;
  }
}

.backWrapper {
  margin-right: auto;
}

.content {
  width: 100%;
}

.title {
  @include title;
  margin-bottom: 10px;
  @include respond-to(m) {
    margin-bottom: 30px;
  }
}

.descriptionWrapper {
  font-size: 14px;
  margin-bottom: 10px;
  color: $secondary_text;
  line-height: 24px;

  strong {
    font-weight: 700;
  }

  @include respond-to(m) {
    max-width: 580px;
    margin: 0 auto 30px auto;
    font-size: 16px;
  }
  @include respond-to(l) {
    max-width: 532px;
    margin-bottom: 20px;
  }
}

.list {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 30px;

  @include respond-to(m) {
    font-size: 16px;
    max-width: 460px;
    margin-left: auto;
    margin-right: auto;
  }

  & li {
    margin-bottom: 6px;
    position: relative;
    padding-left: 18px;

    &:last-of-type {
      margin-bottom: 0;
    }

    &:before {
      position: absolute;
      content: "";
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $primary_violet;
      left: 0;
      top: 7px;
      @include respond-to(m) {
        height: 16px;
        width: 16px;
      }
    }

    @include respond-to(m) {
      margin-bottom: 12px;
      padding-left: 26px;
    }
    @include respond-to(l) {
      margin-bottom: 18px;
    }
  }
}

.buttonWrapper {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);

  padding-bottom: $paddingBottom;

  @include respond-to(m) {
    position: static;
    padding-bottom: 0;
    left: initial;
    bottom: initial;
    transform: none;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
  }
}

.buttonContainer {
  width: 300px;
  text-align: center;
  margin: 0;

  @include respond-to(m) {
    width: 340px;
  }
}

.button {
  height: $buttonHeight;
  width: 100%;

  @include respond-to(m) {
    height: $buttonHeightTablet;
  }
}
