@import "src/assets/scss/breakpoints";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.colorListWrapper {
  max-width: 248px;

  @include respond-to(m) {
    max-width: 365px;
  }
}

.colorList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -8px -20px;

  @include respond-to(m) {
    margin-left: -35px;
    margin-right: -35px;
  }
}

.colorListItem {
  padding: 8px 20px;

  @include respond-to(m) {
    padding-left: 35px;
    padding-right: 35px;
  }
}
