@import "src/assets/scss/breakpoints";
@import "src/assets/scss/vars.module";
@import "src/assets/scss/mixins";

$buttonHeight: 40px;
$buttonTabletHeight: 44px;
$paddingBottom: 30px;
$paddingBottomTablet: 130px;
$paddingBottomDesktop: 0;

.container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 300px;
  padding-bottom: calc(#{$paddingBottom} + #{$buttonHeight});

  @include respond-to(m) {
    width: 340px;
    padding-bottom: $paddingBottomTablet;
  }

  @include respond-to(l) {
    padding-bottom: $paddingBottomDesktop;
    justify-content: center;
  }

}

.text {
  font-size: 14px;
  margin-bottom: 8px;

  @include respond-to(m) {
    font-size: 16px;
    margin-bottom: 20px
  }
}

.buttonWrapper {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);

  padding-bottom: $paddingBottom;

  @include respond-to(m) {
    position: static;
    padding-bottom: 0;
    left: initial;
    bottom: initial;
    transform: none;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
  }

  @include respond-to(l) {
    margin: 0;
  }
}

.buttonContainer {
  margin: 0;
}

.button {
  height: $buttonHeight;

  @include respond-to(m) {
    height: $buttonTabletHeight;
  }

  @include respond-to(l) {
    width: 100%;
  }
}

.answersList {
  margin-bottom: 20px;

  @include respond-to(l) {
    margin-bottom: 50px;
  }
}