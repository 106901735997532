@import "src/assets/scss/breakpoints";

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
  width: 100%;
  position: relative;
  align-self: center;
  padding: 17px 25px 0;
  z-index: 1;
  background: $background;

  @include respond-to(m) {
    padding-top: 22px
  }

  @include respond-to(l) {
    align-self: flex-start;
    padding-top: 30px
  }
}

.menu {
  position: absolute;
  right: 0;
  bottom: 0;
}
