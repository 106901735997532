@import "src/assets/scss/vars.module";
@import "src/assets/scss/breakpoints";
@import "../common.module";

$sectionHeight: 1999;
$sectionHeightTablet: 1802;
$sectionHeightMobile: 1682;

.holder {
  padding-top: calc(#{$sectionHeightMobile} / #{$sectionWidthMobile} * 100%);

  @include respond-to(m) {
    padding-top: calc(#{$sectionHeightTablet} / #{$sectionWidthTablet} * 100%);
  }

  @include respond-to(l) {
    padding-top: calc(#{$sectionHeight} / #{$sectionWidth} * 100%);
  }
}

.buttonWrapper {
  $buttonTopOffset: 1240;
  left: 50%;
  transform: translateX(-50%);
  top: calc(#{$buttonTopOffset} / #{$sectionHeightMobile} * 100%);

  @include respond-to(m) {
    $buttonTopOffset: 1297;
    top: calc(#{$buttonTopOffset} / #{$sectionHeightTablet} * 100%);
  }
}
