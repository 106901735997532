@import "src/assets/scss/breakpoints.scss";
@import "src/assets/scss/mixins.scss";

div.container {
  position: relative;
  justify-content: center;
  align-items: center;
}

.title {
  @include title;
}

.text {
  font-size: 14px;
  color: $secondary_text;
  line-height: 24px;
  @include respond-to(m) {
    font-size: 16px;
  }
  @include respond-to(l) {
    font-size: 18px;
    max-width: 524px;
  }
}

.button, .button_wrapper {
  margin-bottom: 0;
  border-radius: 8px;
  max-width: 330px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  @include respond-to(m) {
    height: 50px;
  }
}

.button_wrapper {
  margin-top: 30px;
}

.store_link_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  max-width: 330px;
  width: 300px;
  @include respond-to(m) {
    width: 340px
  }
  @include respond-to(l) {
    width: 380px
  }
}

.store_link {
  width: 45%
}

.copyright {
  position: absolute;
  bottom: 0;
  margin: auto;
  background: transparent;
}
