@import "src/assets/scss/breakpoints";
@import "src/assets/scss/vars.module";

.list {
  position: absolute;
  width: 100%;
  background: $primary_text;
  margin-top: 10px;
  border-radius: 10px;
  max-height: 265px;
  overflow: scroll;

  /* Hide scrollbar */
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @include respond-to(m) {
    max-height: 285px;
  }
}
