.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0;
  line-height: 0;
}

.text {
  font-size: 9px;
  color: #fff;
  padding-top: 20px;
  padding-bottom: 5px;
}
