@import "src/assets/scss/mixins";
@import "src/assets/scss/breakpoints";

.color {
  display: inline-block;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background: transparent;
  border: 1px solid transparent;
  @include transition(all, 150ms, ease-in-out);

  &:hover {
    @include scale(1.08)
  }

  &:active {
    @include scale(1.15)
  }

  @include respond-to(m) {
    width: 75px;
    height: 75px;
  }
}