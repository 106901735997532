@import "src/assets/scss/breakpoints";
@import "src/assets/scss/vars.module";
@import "src/assets/scss/mixins";

div.wrapper {
  justify-content: space-between;
}

.background {
  background: url("../../../assets/images/background.png") no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.container {
  position: relative;
  @include respond-to(l) {
    display: flex;
    max-width: 720px;
    flex-grow: 1;
    align-items: center;
  }
}

.content {
  width: 100%;
  margin-top: 36px;
  z-index: 1;
  flex-grow: 1;
  @include respond-to(m) {
    margin-top: 53px
  }
  @include respond-to(l) {
    @include flex(column, space-between, center);
    margin-top: 36px;
  }
  & button {
    align-self: flex-start;
  }
}


.quotes {
  text-align: center;
  @include respond-to(l) {
    text-align: left;
    order: 2
  }
}

.stars {
  margin-bottom: 10px;
  @include respond-to(m) {
    margin-bottom: 20px
  }
  @include respond-to(l) {
    margin-bottom: 10px
  }
}

.text {
  font-style: italic;
  font-size: 14px;
  color: $secondary_text;
  line-height: 24px;
  margin-bottom: 20px;
  @include respond-to(m) {
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 30px;
  }
}

.person {
  text-align: center;
  @include respond-to(l) {
    margin-right: 44px;
  }

  & img {
    width: 60px;
    margin-bottom: 10px;
    @include respond-to(l) {
      width: 100px
    }
  }

  & .name {
    margin-bottom: 5px;
    font-size: 18px
  }

  & .region {
    font-style: italic;
    font-size: 14px;
    width: max-content;
    margin: 0 auto;
  }
}

.buttonWrapper {
  margin: 0 auto;
  padding-bottom: 30px;
  text-align: center;
  z-index: 2;
  @include respond-to(m) {
    padding-bottom: 130px
  }
  @include respond-to(l) {
    padding-bottom: 90px
  }
}

.button {
  margin: 0;
}
