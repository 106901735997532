@import "src/assets/scss/breakpoints";
@import "src/assets/scss/vars.module";
@import "src/assets/scss/mixins";

.form {
  @include flex(column, space-between, center);
  flex-grow: 1;
  @include respond-to(l) {
    justify-content: center;
  }
}

.container {
  position: relative;
  width: 100%;
}

.text {
  text-align: center;
  font-size: 14px;
  margin-bottom: 44px;
  color: $secondary_text;
  line-height: 24px;
  @include respond-to(m) {
    max-width: 580px;
    font-size: 16px;
    margin-bottom: 70px;
  }
  @include respond-to(l) {
    font-size: 18px;
    margin-bottom: 30px;
  }
}

.input {
  width: 100%;
  @include respond-to(m) {
    max-width: 380px;
    margin: 0 auto;
  }
}

.block {
  margin-bottom: 22px;

  & > div {
    margin-bottom: 15px;
    @include respond-to(l) {
      margin-bottom: 25px
    }
    @include respond-to(m) {
      margin-bottom: 44px
    }
  }
}


.conditions {
  font-size: 10px;
  margin: 0 auto;
  line-height: 14px;
  color: $secondary_text;
  max-width: 300px;
  @include respond-to(m) {
    font-size: 12px;
    line-height: 18px;
    max-width: 338px;
  }
}
