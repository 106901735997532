@import "vars.module";
@import "breakpoints";

@mixin title($size: 22px, $sizeM: 36px, $sizeL: 40px) {
  font-family: "Open Sans", sans-serif;
  font-size: $size;
  text-align: center;
  line-height: 32px;
  font-weight: 600;

  @include respond-to(m) {
    font-weight: normal;
    font-family: montserrat-semi, sans-serif;
    font-size: $sizeM;
    line-height: 120%;
  }
  @include respond-to(l) {
    font-size: $sizeL;
    font-family: montserrat-medium, sans-serif;
  }
}

@mixin flex($direction: row, $justify: flex-start, $align: flex-start) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}

@mixin flexDirection($direction: row, $media: column) {
  display: flex;
  flex-direction: $direction;
  @include respond-to(m) {
    flex-direction: $media;
  }
}

@mixin transition($property: all, $duration: 100ms, $easing_function: ease-in-out, $delay: 0ms) {
  -webkit-transition: $property $duration $delay $easing_function;
  -moz-transition: $property $duration $delay $easing_function;
  -o-transition: $property $duration $delay $easing_function;
  transition: $property $duration $delay $easing_function;
}

@mixin scale($factor: 1) {
  -webkit-transform: scale($factor);
  -moz-transform: scale($factor);
  -o-transform: scale($factor);
  transform: scale($factor);
}
