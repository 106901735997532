@import "src/assets/scss/breakpoints";
@import "src/assets/scss/mixins";

.container {
  text-align: center;
  align-items: center;
  padding-top: 36px;
  padding-bottom: 30px;

  @include respond-to(m) {
    padding-top: 52px;
    padding-bottom: 130px;
  }

  @include respond-to(l) {
    padding-top: 37px;
    padding-bottom: 90px;
  }
}

.title {
  @include title;
  margin-bottom: 0;
  margin-top: 0;
}

.imageWrapper {
  margin-top: auto;
  margin-bottom: auto;

  padding-top: 10px;
  padding-bottom: 98px;

  @include respond-to(m) {
    padding-bottom: 20px;
  }

  @include respond-to(l) {
    padding-bottom: 30px;
  }
}

.buttonWrapper {
  margin-bottom: 0;

  width: 300px;
  @include respond-to(m) {
    width: 340px;
  }
}

.button {
  width: 100%;
}

.backWrapper {
  margin-right: auto;
}
