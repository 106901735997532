@import "src/assets/scss/breakpoints";

.wrapper {
  position: relative;
  font-size: 0;
  line-height: 0;

  @include respond-to(l) {
    min-width: 200px;
    min-height: 200px;
  }
}
