@import "src/assets/scss/breakpoints";

.progress {
  margin-bottom: 10px;

  @include respond-to(m) {
    margin-bottom: 20px;
  }

  @include respond-to(l) {
    margin-bottom: 0
  }
}
