@import "src/assets/scss/vars.module";
@import "src/assets/scss/breakpoints";
@import "../common.module";

$sectionHeight: 1790;
$sectionHeightTablet: 1773;
$sectionHeightMobile: 1282;

.holder {
  padding-top: calc(#{$sectionHeightMobile} / #{$sectionWidthMobile} * 100%);
  background: $background;

  @include respond-to(m) {
    padding-top: calc(#{$sectionHeightTablet} / #{$sectionWidthTablet} * 100%);
  }

  @include respond-to(l) {
    padding-top: calc(#{$sectionHeight} / #{$sectionWidth} * 100%);
  }
}

.agreement {
  $agreementBlockOffset: 1246;
  width: 100%;
  text-align: center;
  position: absolute;
  z-index: 1;
  font-size: 14px;
  top: calc(#{$agreementBlockOffset} / #{$sectionHeightMobile} * 100%);

  @include respond-to(m) {
    $agreementBlockOffset: 1749;
    font-size: 16px;
    top: calc(#{$agreementBlockOffset} / #{$sectionHeightTablet} * 100%);
  }

  @include respond-to(l) {
    $agreementBlockOffset: 1766;
    font-size: 16px;
    top: calc(#{$agreementBlockOffset} / #{$sectionHeight} * 100%);
  }
}

.button1 {
  $buttonTopOffset: 512;
  top: calc(#{$buttonTopOffset} / #{$sectionHeightMobile} * 100%);

  @include respond-to(m) {
    $buttonTopOffset: 758;
    top: calc(#{$buttonTopOffset} / #{$sectionHeightTablet} * 100%);
  }

  @include respond-to(l) {
    $buttonTopOffset: 762;
    top: calc(#{$buttonTopOffset} / #{$sectionHeight} * 100%);
  }
}

.button2 {
  $buttonTopOffset: 1186;
  top: calc(#{$buttonTopOffset} / #{$sectionHeightMobile} * 100%);

  @include respond-to(m) {
    $buttonTopOffset: 1669;
    top: calc(#{$buttonTopOffset} / #{$sectionHeightTablet} * 100%);
  }

  @include respond-to(l) {
    $buttonTopOffset: 1686;
    top: calc(#{$buttonTopOffset} / #{$sectionHeight} * 100%);
  }
}

.timerBlock {
  $timerBlockOffset: 1585;
  padding: 10px 20px;
  background: #f2c94c;
  border: 3px solid #f2994a;
  width: 91%;
  border-radius: 20px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: calc(#{$timerBlockOffset} / #{$sectionHeightMobile} * 100%);

  @include respond-to(m) {
    $timerBlockOffset: 1930;
    top: calc(#{$timerBlockOffset} / #{$sectionHeightMobile} * 100%);
  }

  @include respond-to(l) {
    width: 52%;
  }
}

.text {
  font-weight: bold;
  width: 148px;
  font-size: 18px;
  line-height: 140%;
  color: white;
  text-transform: uppercase;
}

.highlight {
  margin-left: 5px;
  color: $secondary_violet;
}

.time {
  background: #fff;
  border-radius: 15px;
  color: $secondary_violet;
}

.link {
  color: $primary_text;
}
