@import "src/assets/scss/breakpoints";
@import "src/assets/scss/mixins";
@import "src/assets/scss/vars.module";

div.container {
  justify-content: flex-start;
  align-items: center;
  margin-top: 27px;
  @include respond-to(m) {
    margin-top: 50px
  }
}

.title {
  @include title;
  margin-bottom: 20px;
  @include respond-to(m) {
    margin-bottom: 50px
  }
}

