@import "src/assets/scss/breakpoints";
@import "src/assets/scss/vars.module";

$sectionWidth: 1366;
$sectionWidthTablet: 768;
$sectionWidthMobile: 360;


.sectionButtonWrapper {
  position: absolute;
  z-index: 1;
  width: 330px;
}

.sectionButtonContainer {
  margin: 0;
}

.sectionButton {
  width: 100%;
  background: $btn_primary;
  border-radius: 8px;

  @include respond-to(m) {
    height: 50px;
  }
}
