@import "src/assets/scss/vars.module";
@import "src/assets/scss/breakpoints";

.selectWrap {
  width: 102px;
  position: relative;
  z-index: 1;

  &:before {
    position: absolute;
    content: "";
    display: block;
    background-image: url("../../../assets/images/select.svg");
    width: 12px;
    height: 8px;
    right: 18px;
    top: 16px;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -1;
  }

  @include respond-to(m) {
    width: 122px
  }
}

.select {
  appearance: none;
  width: 100%;
  background-color: transparent;
  color: $primary_text;
  font-size: 14px;
  line-height: 24px;
  text-align: left;
  cursor: pointer;
  outline: none;
  height: 40px;
  border: 1px solid #BDBDBD;
  border-radius: 20px;
  padding: 0 18px;
  transition: all 0.2s ease
}

.item {
  background-color: #0b1339;
}
