@import "src/assets/scss/breakpoints";
@import "src/assets/scss/vars.module";

.container {
  position: relative;
  margin-bottom: 30px;
  @include respond-to(m) {
    margin-bottom: 60px
  }
  @include respond-to(m) {
    margin-bottom: 50px
  }
}

.block {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: -3px;
  left: 0;
  z-index: 4;
}

.value {
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  background: $primary_gradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  @include respond-to(m) {
    font-size: 36px
  }
}
