@import "src/assets/scss/breakpoints";
@import "src/assets/scss/vars.module";

.text {
  font-size: 14px;
  margin-bottom: 44px;
  color: $secondary_text;
  line-height: 24px;
  @include respond-to(m) {
    font-size: 16px;
    margin-bottom: 70px;
  }
  @include respond-to(l) {
    font-size: 18px;
    margin-bottom: 30px
  }
}

.select {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  @include respond-to(m) {
    max-width: 388px
  }
}
