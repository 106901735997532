@import "src/assets/scss/vars.module";
@import "src/assets/scss/breakpoints";

.wrapper {
  padding-top: 32px;
  padding-bottom: 30px;

  @include respond-to(m) {
    padding-top: 47px;
    padding-bottom: 130px;
  }

  @include respond-to(l) {
    padding-top: 30px;
    padding-bottom: 90px;
  }
}

.back {
  margin-bottom: 0;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("/assets/images/background.png") no-repeat;
  background-size: cover;
}

.backWrapper {
  position: relative;
  font-size: 0;
  line-height: 0;
  margin-right: auto;
  margin-bottom: 15px;

  @include respond-to(m) {
    margin-bottom: 23px;
  }

  @include respond-to(l) {
    margin-bottom: 0;
  }
}

.content {
  position: relative;
  width: 100%;
  max-width: 708px;
  margin-left: auto;
  margin-right: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.preview {
  position: relative;
  margin-bottom: 10px;

  &:before {
    content: "";
    display: block;
    padding-top: 48.841%;

    @include respond-to(m) {
      padding-top: 45.34%;
    }
  }

  @include respond-to(m) {
    margin-bottom: 20px;
  }
}

.previewContent {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 16px;

  @include respond-to(m) {
    padding: 40px 35px;
  }
}

.illustration {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.title {
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;
}

.descriptionWrapper {
  margin-top: -10px;
  margin-bottom: 10px;

  @include respond-to(m) {
    margin-top: -20px;
    margin-bottom: 20px;
  }

  p {
    padding-top: 10px;
    font-size: 14px;
    line-height: 24px;

    @include respond-to(m) {
      font-size: 16px;
      padding-top: 20px;
    }
  }

  strong {
    font-weight: 700;
  }
}

.button {
  width: 100%;
}

.buttonWrapper {
  width: 300px;
  margin: auto auto 0;

  @include respond-to(m) {
    width: 340px;
  }
}