@import "src/assets/scss/breakpoints";
@import "src/assets/scss/mixins";

.btn, .secondary {
  @include transition(all, 100ms, ease-in-out);

  &:active {
    @include scale(1.1)
  }
}

.btn {
  display: block;
  margin-bottom: 10px;
  @include respond-to(m) {
    margin-bottom: 20px
  }
  @include respond-to(l) {
    margin-bottom: 0;
    margin-left: 20px;
  }
}

.secondary {
  display: block;
  @include respond-to(m) {
    margin-bottom: 20px
  }
  @include respond-to(l) {
    margin-bottom: 0;
    margin-left: 20px;
  }
}

.img {
  width: 24px;
  height: 24px;
  @include respond-to(m) {
    width: 32px;
    height: 32px;
  }
}

