@import "assets/scss/reset";
@import "assets/scss/fonts";
@import "assets/scss/mixins";
@import "assets/scss/vars.module";

html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $primary_text;
}
